@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face{
    font-family: 'Raleway';
    src: url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
}

html {
    scroll-behavior: smooth;
}

body {
    background-color: #09000C;
    font-family: 'Raleway', sans-serif;
}

h1, h2, h3, h4, h5, h6{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

h1, h2 {
    @apply text-6xl;
    @apply font-bold;
}

p {
    @apply text-xl;
}
